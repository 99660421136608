<template>
  <div>
    <study-list-header />
    <div class="charts">
      <bar-chart
        v-if="barchart"
        id="studies-by-period"
        :height="235"
        :data="barchart"
        colors="var(--purple-light-2)"
      >
        Estudios solicitados
      </bar-chart>
      <bar-horizontal-chart
        v-if="studiesByStatus.data.length !== 0"
        id="studies-by-status"
        :height="235"
        :data="studiesByStatus.data"
        :y-domain="studiesByStatus.yDomain"
        :z-domain="['0', '1']"
        :colors="['var(--purple-light-2)', 'var(--grey-3)']"
        :margin="studiesByStatusMargin"
        :show-stack-labels="true"
        :min-width="600"
      >
        Estudios por estado
      </bar-horizontal-chart>
    </div>
    <d-row>
      <d-col>
        <d-row class="align-items-center my-4">
          <d-col cols="12" md="8" class="mt-3 mt-lg-0">
            <d-form-input
              v-model="searchValue"
              type="text"
              name="doctorInputField"
              placeholder="Ingrese un código de sobre, número de muestra o nombre de oncólogo"
              :value="filters.study.search"
              @input="throttledSearch($event, 'value')"
            />
            <div
              v-if="checkForSearching == true"
              style="color:#5629ff
              font-size: 16px"
            >
              Buscando...
            </div>
          </d-col>
          <d-col class="mt-3 mt-lg-0" cols="12" md="4">
            <pagination
              :total-count="totalCount"
              :current-page="currentPage"
              :current-count="studies.length"
              :limit="limit"
              :next-page="nextPage"
              :go-to-page="goToPage"
              :previous-page="previousPage"
              :position-class="{
                'justify-content-center': true,
                'justify-content-lg-end': true,
              }"
            />
          </d-col>
        </d-row>

        <d-row class="studies">
          <d-col v-if="studies.length == 0" cols="12" md="4" offset-md="4">
            <d-card class="card-small mb-4">
              <d-card-header class="border-bottom">
                <h6 class="m-0">Estudios</h6>
                <div class="block-handle" />
              </d-card-header>
              <d-card-body>
                <p v-if="!isLab()">No se encontraron estudios.</p>
                <p v-if="isLab()">
                  No se encontraron estudios con los filtros solicitados.
                </p>
              </d-card-body>
            </d-card>
          </d-col>
          <d-col
            v-for="(study, id) in studies"
            :key="`study-${id}`"
            cols="12"
            lg="4"
            class="py-3"
          >
            <study-card
              v-if="study.umbrellaId != undefined"
              :study="study"
              :status-labels="studyLabels.status"
              :umbrella-name="
                umbrellasEnabled.find((u) => u.id == study.umbrellaId).name
              "
            />
            <study-card
              v-else
              :study="study"
              :status-labels="studyLabels.status"
              :umbrella-name="null"
            />
          </d-col>
        </d-row>

        <d-row class="align-items-center mt-3">
          <d-col cols="12" class="mt-3 mt-lg-0">
            <pagination
              :total-count="totalCount"
              :current-page="currentPage"
              :current-count="studies.length"
              :limit="limit"
              :next-page="nextPage"
              :go-to-page="goToPage"
              :previous-page="previousPage"
              :position-class="{
                'justify-content-center': true,
                'justify-content-lg-end': true,
              }"
            />
          </d-col>
        </d-row>
      </d-col>
    </d-row>
    <d-modal
      v-if="
        PSPNotifications.length > 0 &&
        $store.state.auth.user.notificationsReaded == false
      "
      style="overflow: auto"
      size="sm"
      :centered="true"
    >
      <div class="inline justify-content-center">
        <d-modal-title class="text-center" style="padding-left: 2.5rem"
          >Avisos</d-modal-title
        >
        <d-button
          id="closeNotificationModal"
          class="bg-white border-white py-1 px-1"
          style="position: sticky; bottom: 1%; left: 66%"
          type="button"
          @click="readAllNotifications()"
          ><span class="material-icons text-accent" style="font-size: 15px"
            >close</span
          ></d-button
        >
        <d-tooltip target="#closeNotificationModal"
          >Cerrar dialogo de notificaciones</d-tooltip
        >
      </div>
      <div class="justify-content-center">
        <notification
          v-for="(notification, index) in PSPNotifications"
          :key="notification.id"
          :notification="notification"
          :index="index"
          :time-until-dismissed="null"
          @hide="hideNotification(notification)"
        />
      </div>
    </d-modal>

    <router-link
      class="floating-action-button"
      :to="{ name: NAMED_ROUTES.STUDY_CREATE }"
    >
      <span class="material-icons">add</span>
      <span class="text">Agregar estudio</span>
    </router-link>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import { NAMED_ROUTES } from '@/utils/constants'
import PlutonBaseMixin from '@/components/mixins/PlutonBaseMixin'
import StudyCard from '@/components/StudyCard'
import StudyListHeader from '@/components/StudyListHeader'
import BarChart from '@/components/Charts/BarChart'
import BarHorizontalChart from '@/components/Charts/BarHorizontalChart'
import Pagination from '@/components/common/Pagination'
import Notification from '@/components/Notifications'
export default {
  components: {
    StudyCard,
    Pagination,
    BarChart,
    BarHorizontalChart,
    StudyListHeader,
    Notification,
  },
  mixins: [PlutonBaseMixin],
  data() {
    return {
      NAMED_ROUTES,
      currentPage: 1,
      limit: 24,
      barchart: null,
      studiesByStatus: {
        data: [],
        yDomain: [],
      },
      checkForSearching: false,
      searchValue: null,
      showNotification: false,
      PSPNotifications: [],
    }
  },
  computed: {
    ...mapGetters('studies', ['studies', 'totalCount']),
    ...mapState('labelMappings', ['studyLabels']),
    ...mapState('filters', {
      filters: (state) => state,
    }),
    ...mapGetters('panels', ['umbrellasEnabled']),
    ...mapState('panels', ['umbrellas']),
    ...mapGetters('auth', ['user']),
    studiesByStatusMargin() {
      const margins = { top: 0, right: 150, bottom: 20, left: 164 }
      const stackedBars = this.studiesByStatus.data.filter(
        (datum) => datum.group === '1'
      )
      if (!stackedBars.length) margins.right = 0
      return margins
    },
  },
  watch: {
    '$store.state.filters': {
      handler: async function (newFilters) {
        this.currentPage = 1
        await this.getStudies({
          newFilters: { ...newFilters },
          page: this.currentPage,
          limit: this.limit,
        })
        const filters = encodeURIComponent(JSON.stringify(newFilters))
        this.barchart = (
          await this.axios.get(`/api2/stats/studies?filters=${filters}`)
        ).data
        this.studiesByStatus.data = (
          await this.axios.get(`/api2/stats/studies-status?filters=${filters}`)
        ).data
        this.studiesByStatus.data.sort((a, b) => a.amount - b.amount)
        this.studiesByStatus.yDomain = this.studiesByStatus.data.map(
          (val) => val.label
        )
      },
      deep: true,
      immediate: true,
    },
    'filters.study.search'(val) {
      this.searchValue = val
    },
  },
  async mounted() {
    this.getPrimaryTumors()
    this.fetchStudyLabels()
    this.fetchPatientLabels()
    this.fetchStudyTestLabels()
    this.getSampleTypes()
    this.getStudies({})
    await this.getUmbrellas()
    this.notificationsLoad()

    this.searchValue = this.$store.state.filters.study.search
  },
  methods: {
    ...mapActions('countries', ['getSampleTypes']),
    ...mapActions('labelMappings', [
      'fetchStudyLabels',
      'fetchPatientLabels',
      'fetchStudyTestLabels',
    ]),
    ...mapActions('primaryTumors', ['getPrimaryTumors']),
    ...mapActions('studies', ['getStudies']),
    ...mapActions('panels', ['getUmbrellas']),
    ...mapActions('auth', ['readAllNotifications']),
    ...mapMutations('filters', ['updateStudyFilters']),

    notificationsLoad() {
      const notif = JSON.parse(JSON.stringify(this.$auth.user().notifications))
      this.PSPNotifications = notif
        .filter(
          (n) =>
            (n.notificationType === 'PSP' ||
              n.notificationType === 'PSP debt advice') &&
            n.readed == false
        )
        .sort((a, b) => {
          const aLength = a.notificationType.split(' ').length
          const bLength = b.notificationType.split(' ').length

          // Ordena en orden descendente por el número de palabras
          return bLength - aLength
        })
      return
    },
    dismissThisNotification({ id }) {
      setTimeout(() => {
        this.hideNotification(id)
      }, 500)
    },
    hideNotification({ id }) {
      this.PSPNotifications = this.PSPNotifications.filter(
        (notification) => notification.id !== id
      )
      if (this.PSPNotifications.length == 0) this.readAllNotifications()
      return
    },
    throttledSearch(value) {
      if (this.searchTimeout) clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.updateStudyFilters({ field: 'search', value: value })
        this.checkForSearching = false
      }, 700)
      this.checkForSearching = true
    },
    async previousPage() {
      if (this.currentPage <= 1) return
      this.currentPage--
      this.setLoading(true)
      await this.getStudies({ page: this.currentPage, limit: this.limit })
      this.setLoading(false)
    },
    async nextPage() {
      if (this.currentPage * this.limit > this.totalStudies) return
      this.currentPage++
      this.setLoading(true)
      await this.getStudies({ page: this.currentPage, limit: this.limit })
      this.setLoading(false)
    },
    async goToPage(pageNumber) {
      this.currentPage = pageNumber
      this.setLoading(true)
      await this.getStudies({ page: this.currentPage, limit: this.limit })
      this.setLoading(false)
    },
    openStudy(item) {
      this.$router.push({
        name: this.NAMED_ROUTES.STUDY,
        params: { id: item.id },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

.clickable,
.clickable * {
  cursor: pointer;
}

.charts {
  display: flex;
  gap: 70px;
  @include phone-only {
    gap: 2rem;
    flex-wrap: wrap;
  }

  & > * {
    flex-basis: 50%;
    @include phone-only {
      flex-basis: 100%;
    }
  }
}

.floating-action-button {
  position: fixed;
  right: 30px;
  bottom: 30px;
  height: 60px;
  padding: 0 18px;
  display: flex;
  gap: 12px;
  align-items: center;
  background-color: var(--purple-light);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.23), 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 30px;
  color: white;
  font-size: var(--font-lg);
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s;

  .material-icons {
    font-size: 24px;
  }

  &:hover {
    background-color: var(--purple);
    box-shadow: var(--shadow-lg);
  }

  .text {
    padding-right: 6px;

    @include phone-only {
      display: none;
    }
  }
}

.notification {
  position: fixed;
  bottom: 10px;
  right: 20px;
  background-color: var(--purple-light);
  color: var(--purple);
  padding: 5px 10px;
  border-radius: 2px;
  z-index: 9999;
  display: flex;
  flex-direction: column-reverse; /* Distribuye las notificaciones de arriba a abajo */
}
</style>
